/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(10, 21, 32, 0.8); /* using --color-bg-primary with opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .modal-content {
    background-color: var(--color-bg-secondary);
    padding: 2rem;
    border-radius: 8px;
    position: relative;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    border: 1px solid var(--color-bg-tertiary);
    box-shadow: 0 4px 6px rgba(10, 21, 32, 0.3);
  }
  
  /* Close Button */
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    line-height: 1;
    color: var(--color-text-muted);
    transition: color 0.3s ease;
  }
  
  .modal-close:hover {
    color: var(--color-text-primary);
  }
  
  /* Modal Content Wrapper */
  .modal-wrapper {
    padding: 1rem;
  }
  
  /* Modal Title */
  .modal-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: var(--color-text-primary);
  }
  
  /* Modal Image Container */
  .modal-image-container {
    width: 100%;
    height: 300px;
    margin-bottom: 1.5rem;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--color-bg-tertiary);
  }
  
  .modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Modal Details */
  .modal-details {
    margin: 1.5rem 0;
  }
  
  .modal-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    color: var(--color-text-secondary);
  }
  
  /* Features Section */
  .modal-features {
    margin: 1.5rem 0;
    background-color: var(--color-bg-tertiary);
    padding: 1.5rem;
    border-radius: 6px;
  }
  
  .features-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--color-teal-light);
  }
  
  .features-list {
    list-style-type: none;
    padding-left: 0;
    margin: 1rem 0;
  }
  
  .feature-item {
    margin-bottom: 0.5rem;
    line-height: 1.4;
    color: var(--color-text-secondary);
    padding-left: 1.5rem;
    position: relative;
  }
  
  .feature-item::before {
    content: "•";
    color: var(--color-teal-primary);
    position: absolute;
    left: 0;
  }
  
  /* Pricing Section */
  .modal-pricing {
    margin: 1.5rem 0;
    padding: 1.5rem;
    background-color: var(--color-bg-tertiary);
    border-radius: 6px;
  }
  
  .pricing-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--color-teal-light);
  }
  
  .pricing-text {
    font-size: 1.1rem;
    color: var(--color-text-secondary);
  }
  
  /* Additional Info */
  .modal-additional-info {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--color-bg-tertiary);
    font-style: italic;
    color: var(--color-text-muted);
  }
  
  /* Action Buttons */
  .modal-actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  
  .book-button {
    background-color: var(--color-teal-primary);
    color: var(--color-text-primary);
  }
  
  .book-button:hover {
    background-color: var(--color-teal-light);
  }
  
  /* Scrollbar Styling */
  .modal-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .modal-content::-webkit-scrollbar-track {
    background: var(--color-bg-secondary);
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background: var(--color-bg-tertiary);
    border-radius: 4px;
  }
  
  .modal-content::-webkit-scrollbar-thumb:hover {
    background: var(--color-teal-dark);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .modal-content {
      width: 95%;
      padding: 1rem;
    }
  
    .modal-title {
      font-size: 1.5rem;
    }
  
    .modal-image-container {
      height: 200px;
    }
  
    .modal-button {
      width: 100%;
    }
  }