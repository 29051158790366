:root {
  --color-bg-primary: #0A1520;
  --color-bg-secondary: #122031;
  --color-bg-tertiary: #1B2B3D;
  --color-teal-primary: #2A9D8F;
  --color-teal-light: #40BCB0;
  --color-teal-dark: #1E7268;
  --color-accent-primary: #E76F51;
  --color-accent-light: #F4947C;
  --color-accent-dark: #C45A3F;
  --color-text-primary: #E8F1F2;
  --color-text-secondary: #B8C5C9;
  --color-text-muted: #738290;
}

/* Base Layout */
.about-page {
  min-height: 100vh;
  width: 100%;
  background-color: var(--color-bg-primary);
}

/* Typography */
.section-title {
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--color-text-primary);
}

.section-description {
  text-align: center;
  max-width: 32rem;
  margin: 0 auto 3rem;
  color: var(--color-text-secondary);
}

/* Hero Section */
.hero-section {
  position: relative;
  padding: 6rem 1.5rem;
  text-align: center;
  background-color: var(--color-bg-tertiary);
  box-shadow: 0 4px 20px var(--color-bg-primary);
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: var(--color-text-primary);
}

@media (min-width: 768px) {
  .hero-title {
    font-size: 3.75rem;
  }
}

.hero-title-accent {
  background-image: linear-gradient(180deg, var(--color-teal-primary), var(--color-accent-dark), var(--color-teal-primary));
  background-size: 100% 300%; /* Extend the gradient vertically */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent; /* For Safari */

  /* Continuous downward animation */
  animation: gradientRoll 32s linear infinite;
}

@keyframes gradientRoll {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 300%;
  }
}

.hero-description {
  max-width: 42rem;
  margin: 0 auto 1rem;
  font-size: 1.125rem;
  color: var(--color-text-secondary);
}

/* Values Section */
.values-section {
  max-width: 72rem;
  margin: 0 auto 2rem auto;
  padding: 2rem 1rem;
}

.values-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .values-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.value-card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--color-bg-secondary);
  border-left: 4px solid var(--color-teal-primary);
  position: relative;
  overflow: hidden;
}

.value-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.value-card-icon {
  margin-right: 0.75rem;
  color: var(--color-teal-primary);
}

.value-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-text-primary);
}

.value-card-description {
  color: var(--color-text-secondary);
}

/* Team Section */
.team-section {
  padding: 5rem 1.5rem;
  background-color: var(--color-bg-secondary);
}

.team-content {
  max-width: 72rem;
  margin: 0 auto;
}

.team-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .team-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.team-member {
  display: flex;
  flex-direction: column;
  object-position: center;
  object-fit: cover;
  align-items: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--color-bg-secondary);
}

.team-member-image {
  object-fit: cover;
  height: 150px; /* Set fixed height */
  width: 150px;  /* Match width to height for a circular shape */
  object-position: center;
  border-radius: 50%;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}

.team-member-name {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: var(--color-text-primary);
}

.team-member-role {
  font-size: 0.875rem;
  color: var(--color-text-secondary);
}

/* Contact Section */
.contact-section {
  max-width: 72rem;
  margin: 0 auto;
  padding: 5rem 1.5rem;
}

.contact-container {
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  background-color: var(--color-bg-tertiary);
}

.contact-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

/* Buttons */
.primary-button {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 0.375rem;
  background-color: var(--color-teal-primary);
  color: var(--color-text-primary);
  border: none;
  cursor: pointer;
  transition: opacity 0.2s;
  box-shadow: 0 0 20px rgba(30, 114, 104, 0.25);
}

.primary-button:hover {
  background-color: #2a9d82a1;
  border: solid 2px #2a9d90a4;
  box-shadow: 0px 0px 10px #40BCB0;
}

.secondary-button {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 0.375rem;
  background-color: transparent;
  color: var(--color-accent-primary);
  border: 2px solid var(--color-accent-primary);
  cursor: pointer;
  transition: opacity 0.2s;
}

.secondary-button:hover {
  opacity: 0.9;
}