.services-page {
    background-color: var(--color-bg-primary);
    color: var(--color-text-primary);
    padding: 2rem;
    min-height: 100vh;
  }
  
  .services-page h1 {
    text-align: center;
    color: var(--color-teal-light);
    margin-bottom: 3rem;
  }
  
  /* Desktop Styles */
  .desktop-view {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .category-section {
    width: 100%;
  }
  
  .category-title {
    color: var(--color-accent-light);
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  
  .category-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .services-row {
    display: flex;
    gap: 1.5rem;
    width: 110%;
  }
  
  .service-card {
    background-color: var(--color-bg-secondary);
    border-radius: 8px;
    box-shadow: -4px 0px 0px var(--color-accent-dark);
    transition: transform 0.2s;
    min-width: calc(33.333% - 1rem);
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .service-image-container {
    width: 100%;
    height: 150%;
    overflow: hidden;
    position: relative;
  }
  
  .service-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-position: center;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    background-color: var(--color-bg-tertiary);
    box-shadow: -3px 0px 1px var(--color-accent-dark);
  }
  
  .service-card:hover .service-image {
    transform: scale(1.05);
  }
  
  .service-content {
    padding: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .service-card h3 {
    color: var(--color-teal-primary);
    margin-bottom: 0.5rem;
  }
  
  .service-card p {
    color: var(--color-text-secondary);
  }
  
  .nav-button {
    position: relative;
    background-color: var(--color-bg-tertiary);
    border: none;
    color: var(--color-text-primary);
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .nav-button:hover:not(:disabled) {
    background-color: var(--color-teal-dark);
  }
  
  .nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Mobile Styles */
  .mobile-view {
    display: none;
  }
  
  @media (max-width: 768px) {
    .desktop-view {
      display: none;
    }
  
    .mobile-view {
      display: block;
    }
  
    .mobile-grid {
      display: grid;
      gap: 2rem;
    }
  
    .mobile-services-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  
    .mobile-category {
      margin-bottom: 2rem;
    }
  
    .service-card {
      width: 100%;
      min-width: unset;
    }
  
    .service-image-container {
      height: 140px;
    }
  }
  
  @media (max-width: 480px) {
    .mobile-services-grid {
      grid-template-columns: 1fr;
    }
  
    .service-image-container {
      height: 180px;
    }
  }