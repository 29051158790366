.navbar {
    background-color: var(--color-bg-primary);
    border-bottom: 1px solid var(--color-bg-tertiary);
    width: 100%;
  }
  
  .navbar-container {
    width: 100%;
    padding: 0;
  }
  
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    padding: 0 1.5rem; /* Moved padding here for better control */
  }
  
  /* Logo styles */
  .navbar-logo {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 0; /* Ensure no left margin */
  }
  
  .logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 0;
  }
  
  .logo-image {
    height: 5rem;
    width: auto;
    object-fit: contain;
  }
  
  /* Desktop navigation */
  .navbar-links-desktop {
    display: none;
    margin-right: 2rem; /* Add some right margin to the navigation links */
  }
  
  @media (min-width: 768px) {
    .navbar-links-desktop {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
  
  /* Rest of your CSS remains the same */
  .nav-link {
    color: var(--color-text-secondary);
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
  }
  
  .nav-link:hover {
  background-image: linear-gradient(180deg, var(--color-teal-primary), var(--color-accent-dark), var(--color-teal-primary));
  background-size: 100% 300%; /* Extend the gradient vertically */
  background-clip: text;
  text-shadow: 0px 0px 10px var(--color-teal-primary);
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent; /* For Safari */
  font-weight: 600;
  /* Continuous downward animation */
  animation: gradientRoll 32s linear infinite;
}

@keyframes gradientRoll {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 300%;
  }
}
  
  
  /* Mobile menu button */
  .navbar-mobile-button {
    display: flex;
    align-items: center;
  }
  
  @media (min-width: 768px) {
    .navbar-mobile-button {
      display: none;
    }
  }
  
  .mobile-menu-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    color: var(--color-text-secondary);
    background-color: transparent;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .mobile-menu-button:hover {
    color: var(--color-text-primary);
    background-color: var(--color-bg-tertiary);
  }
  
  /* Mobile menu */
  .navbar-mobile-menu {
    background-color: var(--color-bg-secondary);
    padding: 0.5rem 0;
  }
  
  @media (min-width: 768px) {
    .navbar-mobile-menu {
      display: none;
    }
  }
  
  .mobile-menu-links {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.5rem 1rem;
  }
  
  .mobile-nav-link {
    display: block;
    color: var(--color-text-secondary);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1rem;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
  }
  
  .mobile-nav-link:hover {
    color: var(--color-text-primary);
    background-color: var(--color-bg-tertiary);
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }